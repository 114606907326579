<template>
<!-- 地推管理页 -->
  <div class="birthdayRight">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>地推管理</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="search_box">
      <span >真实姓名：</span>
      <el-input
      style="margin-right:20px;"
        v-model="formData.trueName"
        placeholder="请输入真实姓名"
      ></el-input>
      <span>手机号码：</span>
      <el-input
        v-model="formData.phone"
        placeholder="请输入手机号码"
      ></el-input>
      <el-button type="primary" class="btn" @click="searchList"
        >查询</el-button
      >

      <el-button
        type="primary"
        class="btn"
        @click="$router.push('/addGroundPush')"
      >
        添加
      </el-button>
    </div>
    <el-table
      border
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      :cell-style="rowClass"  :header-cell-style="headClass"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="staffId" label="地推Id" width="88"> </el-table-column>
      <el-table-column prop="trueName" label="名称" width="133"> </el-table-column>
      <el-table-column prop="phone" label="手机号码" width="133"> </el-table-column>
      <el-table-column prop="summary" label="简介" width="155"> </el-table-column>
      <el-table-column prop="userCount" label="发展会员数量" width="133"> </el-table-column>
      <el-table-column prop="addTime" label="添加时间" width="188"> </el-table-column>
      <el-table-column label="操作" >
        <template slot-scope="{ row }">
             <el-button
            size="mini"
            type="primary"
            style="margin-left: 10px"
            @click="ablGame(row.gameId)"
            v-if="row.status == 0"
          >
            启用
          </el-button>
                <el-button
            type="primary"
            size="mini"
            style="margin-left: 10px"
            @click="disGame(row.gameId)"
            v-if="row.status == 1"
          >
            禁用
          </el-button>
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 10px"
            @click="onDel(row.staffId)"
          >
            删除
          </el-button>
        <el-button type="primary" size="mini" @click="goStaffDetail(row.staffId)">查看详情</el-button>
                <el-button type="primary" size="mini" @click="onOpenCode(row.staffId)">生成二维码</el-button>
                <el-button type="primary" size="mini" @click="onWatch(row.staffId)">查看成员详情</el-button>
               <el-dialog
              title="提示"
              :visible.sync="dialogVisible"
              width="30%"
              :before-close="handleClose">
              <span>是否启用？</span>
     <span slot="footer" class="dialog-footer">
           <el-button @click="dialogVisible = false">取 消</el-button>
           <el-button type="primary" @click="turnOn(row.gameId)">确 定</el-button>
      </span>
    </el-dialog>
               <el-dialog
     title="提示"
     :visible.sync="dialogVisible2"
     width="30%"
     :before-close="handleClose">
     <span>是否禁用？</span>
     <span slot="footer" class="dialog-footer">
           <el-button @click="dialogVisible2 = false">取 消</el-button>
           <el-button type="primary" @click="turnOff(row.gameId)">确 定</el-button>
      </span>
    </el-dialog>
               <el-dialog
     title="提示"
     :visible.sync="dialogVisible3"
     width="30%"
     :before-close="handleClose">
     <span>是否删除？</span>
     <span slot="footer" class="dialog-footer">
           <el-button @click="dialogVisible3 = false">取 消</el-button>
           <el-button type="primary" @click="delStaff(row.gameId)">确 定</el-button>
      </span>
    </el-dialog>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
            <el-dialog
          title="提示"
          :visible.sync="codeVisible"
          width="30%"
          :before-close="handleClose"
          >
          <div class="inner_top">
   <el-input
        style="width:300px"
        v-model.trim="QRcodeSize"
        placeholder="请输入生成的二维码大小"
      
        ></el-input>
        
          <el-button type="primary" @click="generateQRcode">生成</el-button>
        </div>
                <div style="color:red">推荐宽度：280px-1280px</div>
        <el-image
            v-show="imgUrl"
            fit="fill"
            :src="imgUrl"
          ></el-image>
  <span slot="footer" class="dialog-footer">
    <span>右键点击图片，点击另存为保存图片</span>
    <el-button @click="codeVisible = false">取 消</el-button>
    <el-button type="primary" @click="codeVisible = false">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import { staffListAPI,addStaffAPI,staffDetailAPI,delStaffAPI,generateQRcodeAPI } from "../../../api/groundPush";
export default {
  name: "activeList",

  data() {
    return {
      staffId:'',
      codeVisible:false,
      imgUrl:'',
      QRcodeSize:'',
      formData: {
        trueName:'',
        phone:'',
        pageSize: 10,
        currentPage: 1,
      },
      tableData: [],
      pagination: {},
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      staffId:'',
    };
  },
  created() {
    this.getGameList();
  },
  methods: {
                headClass () {
                 return 'text-align: center;'
            },
            // 表格样式设置
            rowClass () {
                return 'text-align: center;'
            },
    onWatch(staffId){
      this.$router.push(`/vipList?staffId=${staffId}`)
    },
       async generateQRcode(){
      this.imgUrl = ''
     if(!this.QRcodeSize){
       this.$message({ message: "请输入宽度", type: "warning" }); 
     }else{
      const {data} = await generateQRcodeAPI({
        staffId:this.staffId,
        widthSize:this.QRcodeSize
      })
      this.imgUrl = data.msg
      console.log(this.imgUrl);
     }
     
    },
    onOpenCode(staffId){
      this.codeVisible = true
      this.staffId = staffId
    },
    onDel(staffId){
      this.dialogVisible3 = !this.dialogVisible3
      this.staffId = staffId
    },
      disGame(row){
        console.log('disGame',row)
        this.dis = row
          this.dialogVisible2 = true
      },
      ablGame(row){
        this.able = row
        console.log('ablGame',row)
        this.dialogVisible=true
      },
      closeModal(){
        this.turnOn()
        this.dialogVisible=false
      },
       handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      //搜索地推列表
      async searchList(){
        const {data} = await staffListAPI(this.formData)
        this.tableData = data.list;
         this.pagination = data.pagination;
      },
      //地推详情
     goStaffDetail(staffId){
         this.$router.push(`/addGroundPush?staffId=${staffId}`)
      },
      //地推列表
      async getGameList(){
          const {data} = await staffListAPI({
              pageSize:this.formData.pageSize,
              currentPage:this.formData.currentPage,
          })
          this.tableData = data.list;
           this.pagination = data.pagination;
      },
      //启动
      async turnOn(row,status){
        //const { data } = 
        await isTurnOn({
          gameId:this.able,
              status:1,
          })
            console.log('开启地推',this.able)
        setTimeout(() => {
            this.dialogVisible =false
        }, 200);
            this.$message({ message: "启用成功", type: "success" }); 
            this.getGameList()
      },
      //禁用
      async turnOff(){
       
          const { data } = await isTurnOn({
            gameId:this.dis,
            status:0,
          })
           console.log('禁用',this.dis)
            setTimeout(() => {
                this.dialogVisible2 =false
        }, 200);
                this.$message({ message: "禁用成功", type: "success" }); 
                this.getGameList()
      },
      //删除地推
      async delStaff(row){
          const {data} = await delStaffAPI({
              staffId:this.staffId
          })
         setTimeout(() => {
            this.dialogVisible3 =false
            this.$message({ message: "删除成功", type: "success" }); 
        }, 200);
          this.getGameList();
      },
    onShowAddress(id) {
      this.address = "components/navPages/navPages?activityId=" + id;
      this.showAddress = true;
    },
    copyinviteUrl() {
      this.$nextTick(() => {
       var inputElement =  document.getElementById("wrapper");
       //选中input框的内容
       inputElement.select();
        // 执行浏览器复制命令
       document.execCommand("Copy");
        this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
      });
    },

    hideVisible() {
      this.dialogVisible2 = false;
    },
    handleSelectionChange(arr) {
      var data = [];
      arr.forEach((item) => {
        data.push(item.activityId);
      });
      this.multipleSelection = data;
      console.log(this.multipleSelection);
    },
    handleSizeChange(num) {
      console.log(num);
      this.formData.pageSize = num;
      this.getGameList();
    },
    handleCurrentChange(num) {
      console.log("currentPage", num);
      this.formData.currentPage = num;
      this.getGameList();
    },
    onInput(value) {
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入正确的数字!",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.birthdayRight {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .btn_box {
    float: left;
    margin-top: 50px;
  }
  .add {
    margin-top: 20px;
  }
  .el-table {
    margin-top: 50px;
  }
  .search_box {
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .el-input {
      width: 200px;
    }
    .btn {
      margin-left: 20px;
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  /deep/.el-dialog__header {
    border-bottom: 1px solid #f1f1f1;
  }
  /deep/.el-dialog__footer {
    border-top: 1px solid #f1f1f1;
  }
  .tips {
    // text-align: right;
    color: orangered;
  }
  .grade_list {
    padding: 0 10px 10px;
    .grade_item {
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 20px;
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
      .grade_name {
        font-weight: bold;
        margin: 0px 0 10px;
      }
      .form_item {
        display: flex;
        .el-form-item {
          flex: 1;
          .el-input-number {
            width: 100%;
          }
        }
      }
    }
  }
  .btn_box {
    margin-top: 24px;
  }
  .modify_btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  /deep/.popconfirm {
    margin-right: 10px;
  }
  #address {
    display: flex;
    align-items: center;

    .el-input {
      width: 350px;
    }
  }
}
  .inner_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-dialog{
  .inner_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-image{
    width: 100%;
  }
z-index: -1;
.el-table[data-v-fd3bb618]{
    margin:0
    }
 .el-pagination[data-v-fd3bb618]{
     margin: 0;
     float: none;
 }
}
</style>